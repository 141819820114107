var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", class: { loading: _vm.model.pageLoading } },
    [
      _c("div", { staticClass: "breadcrumbs" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorDashboard" } } },
                [_vm._v("ダッシュボード")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContentGroups" } } },
                [_vm._v("グループ")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("span", [_vm._v("編集")]),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "folder" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("グループ名")]),
          _c("div", { staticClass: "body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.name.value,
                  expression: "model.name.value",
                },
              ],
              class: { error: _vm.model.name.isError },
              attrs: { type: "text" },
              domProps: { value: _vm.model.name.value },
              on: {
                change: function ($event) {
                  return _vm.validationCheck("name")
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model.name, "value", $event.target.value)
                  },
                  function ($event) {
                    return _vm.validationCheck("name", true)
                  },
                ],
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "errors" },
            _vm._l(_vm.model.name.errors, function (item1) {
              return _c("div", { key: _vm.model.name.errors.indexOf(item1) }, [
                _c(
                  "div",
                  [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                  1
                ),
                _c("span", [_vm._v(_vm._s(item1.message))]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _vm.model.saveButtonCompleted
          ? _c("div", { staticClass: "saveButtonCompleted" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "check" } })],
                1
              ),
              _c("span", [_vm._v("グループ設定を変更しました")]),
            ])
          : _vm._e(),
        !_vm.validationCheck("all", true, true)
          ? _c("div", { staticClass: "saveButtonValidationError" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                1
              ),
              _c("span", [_vm._v("不備のある項目が存在します")]),
            ])
          : _vm._e(),
        _c(
          "button",
          {
            attrs: {
              disabled:
                !_vm.model.saveButtonActive ||
                !_vm.validationCheck("all", true, true),
            },
            on: { click: _vm.save },
          },
          [
            _vm.model.saveButtonLoading
              ? _c("div", { staticClass: "spinner bubble" })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("fa-icon", { attrs: { icon: "save" } })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("span", [_vm._v("グループ設定を適用")])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }